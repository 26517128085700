import * as React from 'react';
import { navigate } from 'gatsby';
import { useState, useEffect } from 'react';
import { PublicCosting } from '../../templates/PublicCosting/PublicCosting';
import { Router } from '@reach/router';
import { MSALAuthInstance, AuthConfig, AuthRequest } from '../../services/auth.service';
import { Login } from '../../components/Login/Login';
import { RouteComponentProps, Redirect } from '@reach/router';
import Page from '../../templates/Page';
import {Portal} from '../../templates/Portal';

import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { SiteGlobalConfig } from '../../site.config';
import { LoadingIndicator } from '../../components/LoadingIndicator/LoadingIndicator';
// import * as styles from './costing.module.scss'
import * as globalStyles from '../../globals.module.scss';


interface ISecureRouteProps extends RouteComponentProps{
    component: any;
    [key: string]: any;
}

export default function SecurePortal(props: ISecureRouteProps){
    const request = {
        scopes: ["User.Read", "Sites.Read.All", "Files.Read"],
        authority: `https://login.microsoftonline.com/${SiteGlobalConfig.MSAL_TENANT_ID}`,
    }

    const [authError, setAuthError] = useState<any>();
    // const { login, result, error } = useMsalAuthentication(InteractionType.Silent, request);
    const { instance, accounts, inProgress } = useMsal();

    const { component: Component, location, path, ...restOfProps } = props;

    // useEffect(() => {
    //     console.log(accounts);
    //     console.log(instance, inProgress);
    //     if(accounts && accounts.length > 0){
    //         instance.setActiveAccount(accounts[0]);
    //         navigate('/secure/portal');
    //     }
    // }, [accounts]);

    useEffect(() => {
        instance.handleRedirectPromise()
            .then((tokenResponse) => {
                if(tokenResponse){
                    // successful auth redirect
                    // navigate('/secure/portal', { replace: true });
                    window.location.pathname = "/secure/portal";
                }
                else{
                    instance.loginRedirect(AuthRequest);
                }
            })
            .catch((err) => {
                console.log(err);
                setAuthError(err);
            });
    },[]);
    
    return (
        <div className={ globalStyles.centerAlignedBody }>
            <AuthenticatedTemplate>
                    <span>Logged in, you are being redirected now...</span>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                {
                    authError ? (
                        <span>Uh oh, there was an error logging you in. Please try again.</span>
                    ) : (
                        <>
                            <LoadingIndicator fontSize='8px' />
                            <span>Logging you in, please wait...</span>
                        </>
                    )
                }
            </UnauthenticatedTemplate>
        </div>
    );
}