import * as React from 'react';
import { useState, useEffect } from 'react';

import * as styles from './LoadingIndicator.module.scss';

//css variables - create styles obj 

export interface ILoadingIndicatorProps{
    fontSize?: string;
    backgroundColor?: string;
}

export const LoadingIndicator = (props: ILoadingIndicatorProps) => {
    const { fontSize, backgroundColor } = props;
     return (
        <div className={ styles.container }>
            <div className={ styles.indicatorContainer } style={{ fontSize: fontSize ? fontSize : "" }}>
            <div className={ styles.leftBar } style={{ "--logo-color": backgroundColor } as React.CSSProperties}></div>
                <div className={ styles.middleBar } style={{ "--logo-color": backgroundColor } as React.CSSProperties}></div>
                <div className={ styles.rightBar } style={{ "--logo-color": backgroundColor } as React.CSSProperties}></div>
            </div>
        </div>
    )
}